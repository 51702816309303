import { FC } from 'react';
import Image from 'next/image';

import styles from './TagIntroBlock.module.scss';

interface TrackImageProps {
    imageThumb: string;
    title: string;
}

export const TrackImage: FC<TrackImageProps> = ({ imageThumb, title }) => {
    return (
        <div className={styles['trackimage-container']}>
            {imageThumb ? (
                <Image
                    alt={title}
                    src={imageThumb}
                    width="0"
                    height="0"
                    style={{ width: 'auto', height: '100%' }}
                    loader={({ src }) => src}
                />
            ) : null}
        </div>
    );
};
