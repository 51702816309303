import { FC, ReactElement } from 'react';

import { Heading, HeadingTag } from '@web/atoms';

interface Props {
    borderBottomColor: string;
    titleHtmlTag?: HeadingTag;
    title: string | ReactElement;
    titleHasSpaces?: boolean;
    titleIsString?: boolean;
    titleString: string;
}

export const Title: FC<Props> = ({
    borderBottomColor,
    title,
    titleHasSpaces,
    titleHtmlTag,
    titleIsString,
    titleString,
}) => (
    <Heading
        as={titleHtmlTag || 'h1'}
        style={{ borderBottom: borderBottomColor }}
        heading={
            titleHasSpaces && titleIsString ? (
                <>
                    <i>{titleString.split(' ')[0]}</i>
                    {titleString.substring(titleString.indexOf(' '))}
                </>
            ) : (
                title
            )
        }
    />
);
