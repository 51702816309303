import { FC, ReactElement } from 'react';
import { isString } from 'lodash';
import { Translate } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';

import { Icon } from '@common/atoms/Icon';
import { GrandPrixEvent, GrandPrixSchedule as ApiGrandPrixSchedule, LocaleID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { Winner } from '@web/atoms/racing/types';

import styles from './GrandPrixSchedule.module.scss';

interface EnhancedEvent extends GrandPrixEvent {
    link: string;
}
export type Event = GrandPrixEvent | EnhancedEvent;

interface EnhancedSchedule {
    date: string | ReactElement;
    events: Event[];
    day?: string | ReactElement;
}

export type Schedule = ApiGrandPrixSchedule | EnhancedSchedule;

export interface Props {
    grandPrixInfo?: string | ReactElement;
    schedule?: Schedule[];
    isPastRace: boolean;
    grandPrixWinners?: { name: string; teamColor: string }[];
    grandPrixSlug?: string;
    isSeperate?: boolean;
    isMiniView?: boolean;
    hasVerticalSchedule?: boolean;
}

export interface EventDate {
    date: string | ReactElement;
    locale: LocaleID;
    hasVerticalSchedule?: boolean;
}

const getScheduleEvents = (
    events: Event[],
    hasVerticalSchedule: boolean = false,
    __translate?: Translate,
): ReactElement => (
    <>
        {events.map((event, index) => {
            const eventClasses = [styles.event];
            if (event.isLive) eventClasses.push(styles['is-live']);

            return (
                <li key={`event-${index}`} className={eventClasses.join(' ')}>
                    {hasVerticalSchedule ? (
                        <span className={[styles.radio, event.isLive ? styles.live : ''].join(' ')} />
                    ) : null}
                    <p className={styles['event-title']}>{event.title}</p>

                    {/* FIXME: in PB-3437 */}
                    {/* <Link  href={event.link} > */}
                    <a>
                        <p className={styles['event-time']}>
                            <>{event.time && event.time.slice(0, event.time.indexOf(' '))}</>
                            <span className={styles['end-time']}>
                                {event.time?.slice(event.time?.indexOf(' '))}
                            </span>
                            {/* {event.isLive ? (
                                        <>
                                            {__translate ? __translate('f1-calendar.liveblog') : 'liveblog'}{' '}
                                            <Icon.arrowsRight />
                                        </>
                                    ) : (
                                        event.time || (
                                            <>
                                                {__translate ? __translate('f1-calendar.uitslag') : 'uitslag'}{' '}
                                                <Icon.arrowsRight />
                                            </>
                                        )
                                    )} */}
                        </p>
                    </a>
                    {/* </Link> */}
                </li>
            );
        })}
    </>
);

const getWinnersList = (grandPrixWinners: Winner[] | undefined) => {
    return (
        <>
            <ul className={styles.winners}>
                {grandPrixWinners?.map((driver, index) => {
                    const driverContent = (
                        <>
                            <span
                                className="tag counter"
                                style={driver.teamColor ? { borderRightColor: `${driver.teamColor}` } : {}}
                            >
                                {index + 1}
                            </span>
                            <p>{driver.name}</p>
                        </>
                    );
                    return (
                        <li key={`driver-${index + 1}`}>
                            {driver.linkTagSlug ? (
                                <InternalLink route={Route.Driver} query={{ tag: driver.linkTagSlug }}>
                                    {driverContent}
                                </InternalLink>
                            ) : (
                                driverContent
                            )}
                        </li>
                    );
                })}
            </ul>
            <hr />
        </>
    );
};

const formatDelimiter: FC<EventDate> = ({ date, locale, hasVerticalSchedule }) => {
    if (!isString(date)) return date;

    const usedFormat: Format = hasVerticalSchedule ? Format.DATE_DAY_NUMERIC_LONG : Format.DATE_DAY_NUMERIC;
    const formattedDate = DateTimeUtil.format(date, usedFormat, locale);

    return (
        <>
            <i>{formattedDate.split(' ')[0]}</i>
            <span className={styles['event-date']}>
                {formattedDate.substring(formattedDate.indexOf(' ')).split('-').join('.')}
            </span>
        </>
    );
};

export const GrandPrixSchedule: FC<Props> = ({
    isPastRace,
    grandPrixInfo,
    grandPrixSlug,
    grandPrixWinners,
    isMiniView,
    isSeperate,
    schedule,
    hasVerticalSchedule,
}) => {
    const __meta = useTranslation('meta').t;
    const classes = ['GrandPrixSchedule', styles.GrandPrixSchedule];
    const { context } = useContextData();

    if (isSeperate) classes.push(styles['is-seperate']);
    if (isMiniView) classes.push(styles['is-mini-view']);
    if (hasVerticalSchedule) classes.push(styles['is-vertical']);

    return (
        <aside className={classes.join(' ')}>
            {isPastRace ? <>{getWinnersList(grandPrixWinners)}</> : null}
            {grandPrixSlug ? (
                <InternalLink route={Route.Grandsprix} query={{ tag: grandPrixSlug }}>
                    <span className={['schedule-info', styles.info].join(' ')}>
                        {grandPrixInfo}
                        <span className={['link-to-gp', styles['link-to-gp']].join(' ')}>
                            <span className={styles['go-to-gp-text']}>
                                {!isMiniView && __meta('f1-calendar.link-to-gp')}
                            </span>
                            <Icon.arrowsRight />
                        </span>
                    </span>
                </InternalLink>
            ) : (
                <>
                    {!hasVerticalSchedule && grandPrixInfo ? (
                        <span className={styles.info}>{grandPrixInfo}</span>
                    ) : null}
                </>
            )}
            {schedule ? (
                <>
                    <hr />
                    <ul className={styles.schedule}>
                        {schedule?.map((day, index) => (
                            <li key={`day-${index}`} className={styles.day}>
                                <p>
                                    {formatDelimiter({
                                        date: day.date,
                                        hasVerticalSchedule,
                                        locale: context.locale,
                                    })}
                                </p>
                                <ul>
                                    {day.events?.length
                                        ? getScheduleEvents(day.events, hasVerticalSchedule, __meta)
                                        : null}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </>
            ) : null}
        </aside>
    );
};
